@font-face {
    src: url(../../assets/Fonts/GeraldtonMedium.woff);
    font-family: GeraldtonExtraLight;
}

@font-face {
    src: url(../../assets/Fonts/Humanist521BoldBT.ttf);
    font-family: Hum521RmBold;
}

body {
    /* font-family: 'GeraldtonExtraLight';
    font-size: 15px; */
}

.home-maincontainer {
    width: 100%;
    background-color: black;
    overflow-x: hidden;
}

.home-image {}

.manage-slider2 {
    width: '100%';
    margin-top: -140
}

li.react-multi-carousel-item {
    text-align: center;
}

.slider-container {
    width: 190%;
    border-radius: 25px;
    display: flex;
    justify-content: space-evenly;
    align-items: space-evenly;
    /* transform: rotate(-10deg); */
    padding: 25px;
    margin-top: -20px;
}

.adjust-images {
    height: 307px;
    width: 320px;
}

.manage-slider-movement {
    width: 110%;
    background-color: black;
    text-align: center;
    /* margin-top: -100px; */
}

.slider-inner-container {}

.slider-inner-image {
    width: 280px;
    height: 280px;
    border-radius: 15px;
    border: 3px solid white;
}

.unique-ghost-container {
    width: 100%;
    background-color: #3f3f3f;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.ghost-subcontainer1 {
    width: 35%;
    padding: 4% 20% 4% 0%;
    display: flex;
    flex-direction: column;
}

.ghost-header-text {
    font-size: 50px;
    font-family: 'Hum521RmBold';
    font-weight: 600;
    color: #F24F60;
}

.ghost-subheader-text {
    font-size: 50px;
    font-family: 'Hum521RmBold';
    font-weight: 600;
    color: white;
}
.topCharacter-heading-div{
    width: 95%;
}
.ghost-desc-text {
    font-size: 20px;
    line-height: 35px;
    margin-top: 25px;
    width: 80%;
    text-align: justify;
    color: white;
}

.ghost-desc-text-reverse {
    font-size: 15px;
    line-height: 25px;
    width: 70%;
    text-align: right;
    color: white;
}

.discord-button-text {
    font-size: 23px;
    color: white;
    font-family: 'Abreu-AzoSans-Black';
    text-decoration: none;
    font-weight: 600;
}

.home-cover-image {
    width: 100%;
}

.maintain-container {
    padding: 5% 15%;
}

.descord-button {
    background-color: #1C39BB;
    padding: 10px 30px;
    border-radius: 30px;
    margin-top: 25px;
}

.ghost-join-button {
    display: flex;
    padding: 5%;
}

.ghost-subcontainer2 {
    width: 200px;
    background-color: #F24F60;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ghost-container-image {
    width: 350px;
    border-radius: 15px;
}

.roadmap-container {
    width: 100%;
    background-color: black;
    /* padding: 5% 15%; */
}

.roadmap-container-reverse {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: black;
    /* padding: 5% 15%; */
}

.container-seperator {
    width: 70%;
    margin-top: 40px;
    border-bottom: 1px solid #242424;

}

.set-desc {
    text-align: center;
    width: 80%;
    margin: 25px 0px;
}

.roadmap-header {
    display: flex;
    align-items: center;

}

.roadmap-header1 {
    font-size: 60px;
    color:  #E722D0;
    text-shadow: 5px 5px 0px #1C39BB;
    transform: skewX(-10deg);
    font-family: 'Hum521RmBold';
    font-weight: 800;
}

.roadmap-image {
    width: 50%;
}

.roadmap-subcontainer {
    margin: 70px;
}

.roadmap-body {
    width: 100%;
}

.roadmap-item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    text-align: left;
    color: white;
    border-bottom: 1px solid rgb(43, 43, 43);
}

.roadmap-title {
    color: white;
    font-family: 'Hum521RmBold';
    font-size: 25px;
}

.roadmap-percentage {
    padding: 15px;
    width: 100px;
}

.roadmap-launchcontainer {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
}

.roadmap-innerlaunch1 {
    width: 40%;
    text-align: center;
}

.roadmap-innerlaunch2 {
    width: 55%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.roadmap-header2 {
    margin-left: 10px;
    font-size: 50px;
    color: white;
    font-family: 'Hum521RmBold';
    font-weight: 800;
}

.roadmap-percent {
    font-family: 'Hum521RmBold';
    font-size: 35px;
    color: #E722D0;
}

.roadmap-subhead {
    font-family: 'Hum521RmBold';
    margin: 15px 0px;
    font-size: 27px;
    color: white
}

.showmore-container {
    width: 100%;
    display: flex;
}

.showmore-button {
    background-color: #F24F60;
    cursor: pointer;
    width: 120px;
    padding: 10px 40px;
    border-radius: 30px;
    margin-top: 25px;
}

.header-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.loader-container {
    background-color: black;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.creativeteam-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.creativeteam-container2 {
    width: 100%;
    display: flex;
    padding-bottom: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3f3f3f;
}

.creativeteam-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.creativeheader-top {
    font-size: 55px;
    color: #E722D0;
    transform: skewX(-10deg);
    text-shadow: 5px 5px 0px #1C39BB;
    font-family: 'Hum521RmBold';
    font-weight: 600;
}

.creativeheader-top2 {
    font-size: 45px;
    color: white;
    font-family: 'Hum521RmBold';
    font-weight: 600;
    margin-left: 10px;
}

.creativeteam-desc {
    font-size: 15px;
    line-height: 25px;
    color: #838383;
}

.creativeteam-teamcontainer {
    width: 100%;
    height: 500px;
}

.creativeteam-background1 {
    position: relative;
    margin: 15px;
}

.creativeteam-member1 {
    width: 100%;
    border-radius: 15px;
    z-index: 1;
}

.creativeteam-background2 {
    position: relative;
    background-color: #0094D2;
    margin: 7px;
    width: 300px;
    height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.creativeteam-member2 {
    width: 200px;
    z-index: 1;
}

.creativeteam-background3 {
    position: relative;
    background-color: #F24F60;
    margin: 7px;
    width: 300px;
    height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.memberdetails-body {
    width: 100%;
    /* position: absolute; */
    transition: 0.2s;
}

.memberdetails-body:hover {
    color: white;
    top: 50%;
    width: 100%;
    text-align: center;
    transition: 0.4s;

}

.memberdetails-body2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.creativemember-text1 {
    font-family: 'Hum521RmBold';
    color: 'white';
    font-size: 25px;
}

.creativemember-text2 {
    font-family: 'Hum521RmBold';
    /* color: #ebdfe6; */
    color: #E722D0;
    font-size: 27px;
}

/* Join Community Container */

.joinCommunity-container {
    width: 100%;
    display: flex;
    background-color: #3f3f3f;
    position: relative;
}

.joincommunity-innercontainer {
    margin: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 70px 150px;
}

.joincommunity-topheader {
    font-size: 100px;
    color: white;
    font-family: 'Hum521RmBold';
    font-weight: 600;
}

.joincommunity-innerheader {
    font-size: 100px;
    font-family: 'Hum521RmBold';
    text-shadow: 5px 5px 0px #1C39BB;
    transform: skewX(-10deg);
    color: #e722d0;
    font-weight: 600;
}

.joincommunity-desc {
    width: 45%;
    line-height: 35px;
    letter-spacing: 3.5px;
    font-size: 20px;
    color: white;
}

.joincommunity-buttoncontainer {
    width: 100%;
    display: flex;
}

.joincommunity-button {
    background-color: #1C39BB;
    padding: 15px 30px;
    margin-top: 25px;
    box-shadow: 5px 5px 15px black;
    border-radius: 25px;
    cursor: pointer;
}

.joincommunity-button-desc {
    font-size: 23px;
    color: white;
    font-family: 'Abreu-AzoSans-Black';
    text-decoration: none;
}

.ghost-join-button a {
    color: 'white';
    text-decoration: none;
}

.joincommunity-buttoncontainer a {
    color: 'white';
    text-decoration: none;
}

.joincommunity-image-body {
    right: 100px;
    z-index: 100;
}

.joincommunity-image {
    width: 450px;
}

.cyclops-container {
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cyclops-innercontainer {
    margin: 70px;
    width: 85%;
}

.cyclops-header {
    margin-top: 50px;
}

.cyclops-carousel-body {
    width: 100%;
    margin: 30px 0px;
}

.carousel-image {
    width: 250px;
    border-radius: 15px;
}

.carousel-image-body {
    margin: 10px;
}

.cyclops-button-container {
    width: 100%;
    display: flex;
}

.cyclops-carousel-button {
    background-color: #F24F60;
    padding: 10px 25px;
    border-radius: 25px;
    margin: 25px;
    cursor: pointer;
}

.cyclops-carousel-button-text {
    color: white;
    font-size: 17px;
    letter-spacing: 3px;
    font-family: 'Hum521RmBold';
}

.topcharacter-body {
    width: 60%;
}

.topcharacter-container {
    /* width: 300px; */
    margin: 15px;
}

.topcharacter-image {
    width: 300px;
    border-radius: 15px;
}

.character-titleheader {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.character-title-text {
    font-size: 25px;
    text-align: center;
    width: 100%;
    color: white;
    font-family: 'Hum521RmBold';
}

.character-label-body {
    background-color: #F24F60;
    padding: 10px 15px;
    border-radius: 25px;
}

.character-label-body2 {
    background-color: #FFEE00;
    padding: 10px 15px;
    border-radius: 25px;
}

.character-label-body3 {
    background-color: #0094D2;
    padding: 10px 15px;
    border-radius: 25px;
}

.character-label-text {
    font-size: 20px;
    color: white;
    font-family: 'Hum521RmBold';
}

.character-seperator {
    width: 100%;
    /* border: 0.5px solid #161616; */
    margin: 20px 0px;
}

.character-desc {
    font-size: 20px;
    line-height: 35px;
    color: white;
}

.character-showmore-body {
    display: flex;
    margin: 35px 0px;
}

.character-showmore-button {
    /* background-color: #F24F60; */
    padding: 10px 25px;
    border-radius: 25px;
    cursor: pointer;
}

.character-showmore-button-text {
    color: white;
    font-size: 20px;
    font-family: 'Hum521RmBold';
}

.frequentlyasked-container {
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.frequentlyasked-top {
    font-size: 55px;
    transform: skewX(-10deg);
    text-shadow: 5px 5px 0px #1C39BB;
    color: #E722D0;
    font-family: 'Hum521RmBold';
}

.topCharacters-heading {
    border-radius: 35px;
    background-color: white;
    border-radius: 5px 5px 15px black;
    width: 100%;
    text-align: center;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20px 30px; */
    margin: 10px 0px;
    cursor: pointer;
    font-family: 'Hum521RmBold';
    font-size: 20px;
}

.topcharacter-desc {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    color: white;
}

.topcharacter-desc2 {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    color: white;
}

.topcharacter-body {
    width: 70%;
}

.frequentlyasked-body {
    width: 70%;
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {
    .maintain-character {
        /* background-color: red; */
    }

    .topcharacter-image {
        width: 200px;
    }

    .character-title-text {
        font-size: 20px;
    }
}

@media screen and (max-width: 1025px) and (min-width: 600px) {
    .joincommunity-image {
        width: 350px;
    }

    .roadmap-image {
        width: 70%;
    }

    .roadmap-title {
        font-size: 20px;
        text-align: center;
    }

    .roadmap-percent {
        font-size: 22px;
    }

    .joincommunity-topheader {
        font-size: 50px;
    }

    .joincommunity-innerheader {
        font-size: 60px;
    }

    .manage-slider-movement {
        margin-top: -150px;
    }

    .creativeteam-background1 {
        width: 90%;
        border-radius: 15px;
    }

    .creativeteam-teamcontainer {
        flex-wrap: wrap;
    }

    .slider-inner-image {
        width: 240px;
        height: 240px;
        border-radius: 15px;
        border: 3px solid white;
    }

    .slider-container {
        width: 230%;
        border-radius: 25px;
        display: flex;
        justify-content: space-evenly;
        align-items: space-evenly;
        /* transform: rotate(-10deg); */
        padding: 25px;
        margin-top: 160px;
    }

    .manage-slider2 {
        width: 100%;
        margin-top: -200px;
    }

    .showless-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .creativeteam-container {
        /* margin: 70px 0px; */
    }

    .ghost-join-button {
        margin-bottom: 30px;
        padding: 0px;
    }

    .joincommunity-desc {
        width: 80%;
    }

    .character-title-text {
        width: 100%;
        text-align: center;
    }

    .joincommunity-innercontainer {
        margin: 80px 50px;
    }

    .joinCommunity-container {
        padding: 50px 0px;
    }

    .ghost-desc-text {
        width: 100%;
        margin-top: 35px;
    }
}

@media screen and (max-width: 768px) and (min-width: 426px) {
    .creativeteam-teamcontainer {
        flex-wrap: wrap;
    }
    .creativemember-text1{
        font-size: 20px;
    }
    .creativemember-text2{
        font-size: 23px;
    }
    .frequentlyasked-container {
        align-items: flex-start;
    }

    .frequentlyasked-body {
        width: 100%;
    }

    .topcharacter-desc {
        width: 107%;
    }

    .roadmap-header {
        justify-content: center;
    }

    .roadmap-subcontainer {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;
    }

    .roadmap-launchcontainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .roadmap-innerlaunch2 {
        width: 95%;
        margin-top: 25px;
    }

    .roadmap-image {
        width: 100%;
    }

    .creativeteam-background1 {
        border-radius: 15px;
        width: 90%;
    }

    .ghost-subcontainer1 {
        width: 95%;
        padding: 0px;
        margin-left: 15px;
    }

    .ghost-subcontainer2 {
        width: 100%;
        background-color: #3f3f3f;
    }

    .unique-ghost-container {
        flex-direction: column-reverse;
        margin-top: 50px;
    }

    .joinCommunity-container {
        flex-direction: column;
    }

    .joincommunity-innercontainer {
        width: 95%;
        margin: 10px;
        margin-bottom: 50px;
    }

    .joincommunity-desc {
        width: 100%;
    }

    .header-button-container {
        height: 70px;
    }

    .joincommunity-image-body {
        width: 100%;
        position: relative;
        text-align: right;
    }

    .slider-inner-image {
        margin: 5px;
        width: 200px;
        height: 200px;
    }

    .ghost-subcontainer1 {
        margin-top: 50px;
        text-align: center;
    }

    .ghost-desc-text {
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }

    .ghost-join-button {
        width: 100%;
        justify-content: center;
    }

    .showmore-container {
        justify-content: center;
    }

    .frequentlyasked-container {
        margin: 25px 0px;
    }

    .creativeteam-header {
        margin-top: 30px;
    }

    .joincommunity-buttoncontainer {
        justify-content: center;
    }
}

@media screen and (max-width: 428px) and (min-width: 320px) {
    .creativeteam-teamcontainer {
        flex-direction: column;
        height: 400px;
    }

    .character-desc {
        width: 95%;
        margin-left: 1%;
        text-align: justify;
    }

    .header-button-container {
        height: 50px;
        margin-top: 10px;
        align-items: flex-end;
    }

    .creativeheader-top2 {
        text-align: center;
    }

    .creativeteam-header {
        flex-direction: column;
    }

    .character-titleheader {
        justify-content: center;
        text-align: center;
    }
    .creativeteam-header{
        margin-top: 20px;
    }
    .frequentlyasked-body{
        width: 100%;
    }

    .topCharacters-heading {
        font-size: 15px;
        width: 100%;
        text-align: center;
    }

    .roadmap-container-reverse {
        align-items: center;
        width: 98%;
        /* margin-left: 2.5%; */
        justify-content: center;
        padding: 0px;
        margin-top: 35px;
    }

    .roadmap-maincontainer {
        width: 100%;
        padding: 50px 0px;
    }

    .roadmap-container {
        width: 100%;
        background-color: black;
        margin-top: 75px;
        padding: 0px;
    }

    .ghost-header-text {
        font-size: 30px;
    }

    .roadmap-header1 {
        font-size: 35px;

    }

    .roadmap-header2 {
        font-size: 45px;
    }

    .roadmap-header {
        flex-direction: column;
        width: 100%;
    }

    .roadmap-subhead {
        width: 80%;
        text-align: center;
    }

    .ghost-subheader-text {
        font-size: 35px;
        text-align: center;
        margin-bottom: 15px;
        width: 100%;
    }

    .creativeteam-background2 {
        border-radius: 15px;
    }

    .creativeteam-background1 {
        border-radius: 15px;
        width: 93%;
    }

    .joinCommunity-container {
        flex-direction: column;
        padding-top: 50px;
    }

    .joincommunity-image-body {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        right: 0px
    }

    .joincommunity-image {
        width: 300px;
    }

    .joincommunity-innercontainer {
        margin: 0px !important;
        text-align: center;
    }

    .joincommunity-desc {
        width: 100%;
    }

    .joincommunity-buttoncontainer {
        justify-content: center;
    }

    .carousel-image-body {
        width: 100%;
        text-align: center;
    }

    .cyclops-button-container {
        justify-content: center;
    }

    .unique-ghost-container {
        flex-direction: column;
        justify-content: center;
    }

    .ghost-subcontainer1 {
        width: 95%;
        margin-left: 2.5%;
        justify-content: center;
        align-items: center;
    }

    .ghost-subcontainer2 {
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: black;
    }

    .joincommunity-topheader {
        font-size: 40px;
    }

    .joincommunity-innerheader {
        font-size: 40px;
    }

    .joincommunity-desc {
        margin: 35px 0px;
    }

    .slider-container {
        width: 300%;
    }

    .ghost-desc-text {
        width: 100%;
        text-align: justify;
    }

    .slider-inner-image {
        width: 130px;
        height: 130px;
        border: 2px solid white;
        margin: 5px;
    }

    .manage-slider-movement {
        margin-top: 40px;
    }

    .maintain-container {
        padding: 0px;
        width: 95%;
        margin-left: 2.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ghost-desc-text {
        text-align: justify;
        width: 100%;
    }

    .ghost-desc-text-reverse {
        text-align: justify;
        width: 100%;
    }

    .showmore-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .react-multi-carousel-item {
        text-align: center;
    }

    .topcharacter-image {
        width: 220px;
    }

    .roadmap-launchcontainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .roadmap-innerlaunch2 {
        width: 95%;
        margin-top: 50px;
    }
    .roadmap-percent{
        font-size: 20px;
    }
    .roadmap-title{
        font-size: 20px;
        width: 90%;
    }
    .roadmap-image {
        width: 100%;
    }
    .creativeteam-background1 {
        border-radius: 15px;
        width: 90%;
    }
    .roadmap-item-container{
        margin-top: 15px;
    }
    .frequentlyasked-container {
        align-items: flex-start;
    }
    .topCharacters-heading{
        /* width: 100%; */
    }
    .ghost-subcontainer2 {
        width: 100%;
        background-color: #3f3f3f;
    }
    .header-button{
        width: 150px;
    }
    .descord-button{
        padding: 15px 20px;
    }
    .roadmap-innerlaunch1{
        width: 80%;
    }
    .roadmap-title{
        font-size: 17px;
    }
}

@media screen and (max-width: 475px) and (min-width: 320px) {
    .topCharacters-heading{
        /* width: 81%; */
    }
    .frequentlyasked-top{
        font-size: 45px;
    }
}