.footer-container{
    background-color: #3f3f3f;
    display: flex;
    width: 100%;
    overflow-x: hidden;
    /* margin-top: 30px; */
}
.footer-innercontainer{
    margin:80px 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-section1{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.footer-section2{
    width: 40%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.footer-socialmedia-body{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 40%;
    margin-top: 20px;
}
.footer-logo{
    width: 120px;
}
.social-icons{
    width: 45px;
}
.copyright-font{
    color: white;
    font-size: 25px;
    font-family: 'Abreu-AzoSans-Black';
    margin-top: 30px;
}

@media screen and (max-width: 768px) and (min-width: 426px) {
    .footer-innercontainer{
        flex-direction: column;
        background-color: black;
        margin: 0px;
    }
    .footer-character{
        width: 100%;
    }
    .footer-section2{
        width: 100%;
        height: 350px;
        margin-top: 100px;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .footer-character{
        width: 100%;
    }
    .footer-innercontainer{
        flex-direction: column;
        margin:50px 10px;
        text-align: center;
    }
    .footer-section2{
        margin-top: 70px;
        height: 250px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
}