@font-face {
    src: url(../../assets/Fonts/HUM521B.ttf);
    font-family: HUM521B;
}
@font-face {
    src: url(../../assets/Fonts/Abreu-AzoSans-Black.otf);
    font-family: Abreu-AzoSans-Black;
}

.header-container{
    width: 100%;
    height: 100px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-subContainer1{
    width: 40%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.header-subContainer2{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.header-menu a{
    color: #FFFFFF;
    text-decoration: none;
}
.header-logo{
    width: 60px;
}
.header-button{
    padding: 10px;
    background-color: #1C39BB;
    transform: skewX(-10deg);
    box-shadow: 5px 5px 0px #E722D0;
    text-decoration: none;
    font-size: 15px;
}
.header-options{
    width: 40px;
}
.header-menu{
    width: 350px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.header-descord{
    color: white;
    font-family: 'Abreu-AzoSans-Black';
    font-weight: 800;
    font-size: 20px;
    text-decoration: none;

}
@media screen and (max-width: 1025px) and (min-width: 769px) {
    .header-button{
        padding:10px;
    }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
    .header-descord{
        font-size: 15px;
        text-align: center;
        width: 100%;
    }
    .header-button{
        /* padding: 10px; */
        width: 200px;
    }
    .header-subContainer2{
        width: 45%;
    }
}

@media screen and (max-width: 429px) and (min-width: 320px) {
    .header-descord{
        font-size: 15px;
    }
    .header-button{
        /* width: 80px; */
    }
    .header-subContainer2{
        width: 70%;
    }
    .header-subContainer1{
        width: 25%;
    }
}