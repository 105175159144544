.drawer-container{
    width: 100%;
    height: 100%;
    font-size: 100px;
    z-index: 10000;
    position: fixed;
    display: flex;
    /* overflow: hidden; */
    overflow: auto;
}
.drawer-container-mobile{
    width: 100%;
    height: 100vh;
}
.drawer-innercontainer1{
    width: 80%;
    background-color: black;
    opacity: 0.4;
    height: 100vh;
}
.drawer-innercontainer2{
    width: 25%;
    height: 100%;
    background-color: black;
    box-shadow: -5px -5px 55px black;
    display: flex;
    flex-direction: column;
    overflow: auto;
    /* justify-content: center; */
}
.drawermobile-innercontainer2{
    width: 100%;
    background-color: black;
    box-shadow: -5px -5px 55px black;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
.drawer-buttonContainer{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.hide{
    visibility: hidden;
}
.drawer-buttonContainer1{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
}
.drawer-closeButton{
    display: flex;
    cursor: pointer;
}
.drawer-titleButton{
    color: white;
    font-family: 'Abreu-AzoSans-Black';
    font-size: 25px;
    width: 80%;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
}
.drawer-titleButton:hover{
    letter-spacing: 3px;
    transition: 0.3s;
}
.drawer-items{
    color: white;
    cursor: pointer;
    text-decoration: none;
}
.drawer-items{
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin: 30px 0px;
    font-family: 'Abreu-AzoSans-Black';
}

@media screen and (max-width: 1024px) and (min-width: 428px) {
    .drawer-innercontainer2{
        width: 50%;
    }
    .drawer-items{
        font-size: 28px;
        margin:15px 0px;
    }
}

@media screen and (max-width: 428px) and (min-width: 320px) {
    .drawer-items{
        font-size: 25px;
        margin:10px 0px;
    }
}